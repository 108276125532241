import React from 'react';
import { Link } from 'gatsby';
import Showcase from '../../Showcase/Showcase.js';
import cardImage from '../../images/cardbg.png';

import styled from "../../../components/images/styledc.png";

export const Hero2 = () => {

  return (
    <div className="container">
      <div className="feature feature-2">
        <div className="feature__wrap">
          <div className="feature__left">
            <img
              src={require('../../../components/images/adminfeatfree.png')}
              alt="#"
              className="feature__img"
            />
          </div>
          <div className="feature__right">
            <h2 className="feature__lead feature__lead--1 showcase__lead">
              Robust UI components
            </h2>
            <p className="feature__text mb-5">
              Contrast Angular Bootstrap has over 2000+ varaints and  <a href="/product/angular-contrast-pro/"> Contrast Angular Bootstrap Pro </a>  ships with over 10000+ variants of its UI components which have also been well documented to show the different ways they can be combined. 
            </p>
            <a target="_blank" rel="noreferrer" href="https://cdbangular-demo.netlify.app/" className="btn1 btn__inverse">
              Preview Components
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
