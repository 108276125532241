import React from 'react';
import Helmet from 'react-helmet';

import AppContext, { UserContext } from "../../components/AppContext";
import NavBar from "../../components/NavBar/NavBar";
import { Footer } from "../../components/Footer/Footer";
import { Hero } from '../../components/About/Angular/Hero';
import { Hero2 } from '../../components/About/Angular/Hero2';
import { Hero3 } from '../../components/About/Angular/Hero3';
import { VideoSection } from '../../components/About/Angular/VideoSection';
import { FeaturedIn } from '../../components/FeaturedIn/FeaturedIn';
import { PagesShowcase } from '../../components/About/Angular/PagesShowcase';
import { DocsPreview } from '../../components/About/Angular/DocsPreview';
import { Comparison } from '../../components/About/Angular/Comparison';
import { Feature } from '../../components/About/Angular/Feature';
import { Showcase } from '../../components/About/Angular/Showcase';
import { About as AboutSection } from '../../components/About/Angular/About';
import '../../styles/scss/style.scss'


const Contrast = () => {
  return (
    <AppContext>
      <Helmet>
        <title>Angular Bootstrap UI Kit Library - Contrast</title>
        <meta name="title" content="Angular Bootstrap UI Kit Library - Contrast" />
        <meta
          name="description"
          content="Angular Bootstrap Elegant UI Kit and reusable components for building mobile-first, responsive webistes and web apps"
        />
        <meta property="og:title" content="Angular Bootstrap UI Kit Library - Contrast" />
        <meta
          property="og:description"
          content="Angular Bootstrap Elegant UI Kit and reusable components for building mobile-first, responsive webistes and web apps"
        />
        <meta property="twitter:title" content="Angular Bootstrap UI Kit Library - Contrast" />
        <meta
          property="twitter:description"
          content="Angular Bootstrap Elegant UI Kit and reusable components for building mobile-first, responsive webistes and web apps"
        />
        <meta property="og:site_name" content="Devwares" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="og:image" content="https://res.cloudinary.com/speedwares/image/upload/v1643031081/contrast-bootstrap_p456h2.jpg" />
        <meta name="twitter:image" content="https://res.cloudinary.com/speedwares/image/upload/v1643031081/contrast-bootstrap_p456h2.jpg" />

      </Helmet>
      <UserContext.Consumer>
        {({ user }) => (
          <div>
            <div className="container">
              <NavBar user={user} />
              <Hero />
            </div>
            <VideoSection />
            <div className="container mt-5">
              <FeaturedIn />
              <Feature />
              <Hero3 />
              <Hero2 />
              <AboutSection />
              <PagesShowcase />
            </div>
            <DocsPreview />
            <div className="container mt-5 pt-5">
              <Showcase />
              <Comparison />
              <Footer />
            </div>
          </div>
        )}
      </UserContext.Consumer>
    </AppContext>
  );
};

export default Contrast;