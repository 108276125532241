import React from 'react';
import { Link } from 'gatsby';

export const Feature = () => {
  return (
    <div className="feature feature-2">
      <div className="feature__wrap">
        <div className="feature__left">
          <img
            src={require('../../../components/images/aboutfeatfree.png')}
            alt="#"
            className="feature__img"
          />
        </div>
        <div className="feature__right">
          <h2 className="feature__lead feature__lead--1 showcase__lead">
            Seamlessly created angular reusable UI components to make your next project as beautiful.
          </h2>
          <p className="feature__text">
            Contrast Angular Bootstrap is an elegant bootstrap UI kit featuring over 2000+ basic components,
            which can be integrated with any angular project to build mobile first, responsive and 
            elegant websites and web apps.
          </p>
          <ul className="feature-2__list">
            <li>
              Over 2000 components included some of which are buttons,
              forms, alerts, datepickers, cards and many more. Also get an admin dashboard template built with contrast
            </li>
            {/* <li>
              More than 30 example pages that can easily be used to get you started on your angular project.
            </li> */}
          </ul>
          <Link to="/docs/contrast/angular/index" className="btn1 btn__inverse">
            View Documentation
          </Link>
        </div>
      </div>
    </div>
  );
};
