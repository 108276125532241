import React from 'react';
import { Link } from 'gatsby';


export const Hero3 = () => {

  return (
    <div className="container">
      <div className="feature feature-2">
        <div className="feature__wrap">  
          <div className="feature__left">
            <h2 className="feature__lead feature__lead--1 showcase__lead">
               Admin Dashboards templates
            </h2>
            <p className="feature__text mb-5">
              We built a Free and PRO admin template using Contrast Angular Bootstrap. This includes pages and dashboards which you can easily used to get
               started on any sass, admin, prelaunch or web app projects.
            </p>
            <Link to="/product/angular-admin-contrast" className="btn1 btn__inverse">
              Download Admin template
            </Link>
          </div>
          <div className="feature__right">
            <img
              src={require('../../../components/images/adminheropro.png')}
              alt="#"
              className="feature__img"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
