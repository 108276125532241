import React from 'react';
import { Link } from "gatsby";


export const About = () => {
  return (
    <div className="container">
      <div className="feature feature-2">
        <div className="feature__wrap">
        <div className="feature__left">
          <h2 className="feature__lead feature__lead--1 hero__lead">
            Get Started using Contrast in 2 minutes
            </h2>
          <p className="feature__text">
            We packaged the Contrast Bootstrap Angular as an npm package that can be installed via <b>npm</b> or <b>yarn</b>.
            Which means you can get started using it by just running a single command <b>npm install ng-cdbangular</b>
          </p>
          <p className="feature__text">
              We have also designed a Pro version of Contrast to give you access to more features and improvements. 
  
            </p>
          <div className="feature__buttons">
            <Link to="/docs/contrast/angular/Installation/" className="btn1 btn__inverse">
              <span className="btn__text">Install Contrast Angular</span>
            </Link>
            <Link to="/product/angular-contrast-pro/" className="btn1 btn-white">
                <span className="btn__text" style={{ color: "#3329e0" }}>Get Contrast Angular Pro</span>
            </Link>
          </div>
        </div>
        <div className="feature__right">
          <img
            src={require('../../../components/images/aboutlastfree.png')}
            alt="#"
            className="feature__img"
          />
        </div>
      </div>
      </div>
      </div>
  );
};
